.rf-centereddiv {
    display: flex;
    flex: 1;
    justify-content: 'space-between';
    align-items: 'stretch';
    margin-top: 22px;
  }
  .rf-modaldiv {
    align-self: 'center';
    width: '90%';
    border-radius: 15px;
    background-color:#80c0ff;
    padding: 28px;
    align-items: 'flex-start';
    /* shadowColor: constants.black;
    .shadowOffset {
      width: 0;
      height: 2)
    }
    shadowOpacity: 0.25; */
    /* shadowRadius: 4) */
    z-index: 5px;
    margin: 20px;
    justify-content: center;
    max-height: 80%;
    overflow-y: scroll;
  }
  .rf-row {
    display: flex;
    flex-direction: row;
  }
  .rf-column {
    display: flex;
    flex-direction: column;
  }
  .rf-textInput {
    border-bottom-color: #0052ca;
    border-bottom-width: 1px;
    margin: 10px;
    padding-bottom: 10px;
    width: 40px;
    margin-left: 0;
  }
  .rf-greyOut {
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: rgba(0,0,0,0.3)
  }
  .rf-dropdownMover {
    /* height: 12px;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: -9px;
    left: 60px;
    width: 34px;
    margin-bottom: -9px; */
  }
  .rf-xIcon {
    width: 30px;
    height: 30px;
    background-size: contain;
  }
  .rf-closeIconContainer {
    position: absolute;
    top: -1px;
    right: 28px;
  }
  .rf-label {
    font-family: 'Inter-Regular', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 37px;
    letter-spacing: -0.15px;
    text-align: left;
    color: #FFFFFF;
    width: 98px;
  }
  .rf-value {
    font-family: Inter;
    font-size: 14px;
    color: #0052ca;
    position: relative;
    top: 8px;
  }
  .rf-formContainer1 {
    margin-left: 36px;
    margin-right: 36px;
    width: 90%;
  }
  .rf-dropdownMover1 {
    /* position: relative;
    top: -5px;
    height: 12px;
    left: -3px;
    width: 34px;
    margin-bottom: -6px;
    z-index: 0; */
  }
  .rf-selectedpStyle {
    text-align: left;
    color: #0052ca;
    margin-left: 10px;
    background-color:#80c0ff;
  }
  .rf-containerStyle {
    display:inline;
    border-width: 0.5px;
    border-color: #FFFFFF;
    border-top-color:#80c0ff;
    background-color:#80c0ff;
    color:#0052ca;
    box-shadow: 0px 1px 1.1px #0052ca;
    z-index:2;
  }

  .rf-checkboxChecked::after {
    content: '✓';
  }

  .rf-checkbox {
    cursor: pointer;
    border:1px solid #0052ca;
    padding: 4px;
    border-radius: 3px;
    height: 10px; width: 10px;
  }
/* 
// these styles are used; passed on as arguements
// for the old dropdown
const styleOverride = StyleSheet.create({
  .container {
    flex: 1;
    padding: 40;
  }
  .rf-dropdown {
    background-color:#80c0ff;
    border-bottom-color: #FFFFFF;
    border-bottom-width: 0.5;
    margin-top: 20;
  }
  .rf-item {
    padding: 17 4 17 4;
    flex-direction: row';
    justify-content: 'space-between';
    align-items: 'center';
  }
  .rf-textItem {
    flex: 1;
    font-size: 16;
    color: #0052ca;
  }
  .rf-icon {
    width: 20;
    height: 20;
  } */