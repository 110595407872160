.cc-bleAwareness {
    width: 17px;
    height: 17px;
    background-size: contain;
  }
  .cc-padDown {
    /* position: relative; */
    padding: 20px;
    border-radius: 4px;
    /* border: 0.5px solid #0052ca; */
    /* box-shadow: 4px 4px 6px rgba(0,0,0,.5); */
    margin-bottom: 20px;
  }
  .cc-deviceNameHeader {
    position: relative;
    top: -110px;
    left: 62px;
    z-index: 0;
    font-size: 13.3px;
  }
  .cc-voltageLabels {
    width: 90px;
    height: 17px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 19.8px;
    letter-spacing: -0.27px;
    text-align: left;
    color: #d1d3d4;
  }
  .cc-backSplash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0052ca;
    opacity: 0.9;
    z-index: 10;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }