@font-face {
  font-family: 'Inter-Regular';
  src: url(../assets/fonts/Inter-Regular.ttf) format('ttf');
}

.buttonContainer {
    width: 100%;
    background: var(--elevation-dark-blue);
    height: 46px;
  }
  .mainButton {
    font-family: 'SpaceGrotesk-Bold';
    font-size: 15.7px;
    font-weight: bold;
    font-style: normal;
    line-height: 47px;
    letter-spacing: 0;
    text-align: left;
    margin-left: 14px;
    color: var(--elevation-white);
  }
  .disabled {
    background: var(--elevation-dark-grey)
  }
  .buttonArrow {
    width: 13px;
    height: 11.4px;
    margin-top: 18px;
    margin-right: 13.7px;
    background-size: contain;
  }
  .buttonArrangement {
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
  .screen {
    flex: 1;
    padding: 24px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-top: 0;
    margin-bottom: 50px;
    padding-left: 0;
    border-radius: 16px;
    margin-top: 30px;
  }
  .logo {
    width: 43px;
    height: 35.7px;
    background-size: contain;
  }
  .gear {
    width: 28px;
    height: 28px;
  }
  .helpIcon {
    width: 23.3px;
    height: 23.3px;
  }
  .gutterWrapper {
    flex-direction: column;
    flex: 1;
    padding: 38px;
  }
  .defaultContainer {
    flex: 1;
    background: var(--elevation-white);
  }
  .loginContainer {
    flex: 1;
  }
  .loginInputContainer {
    flex: 2;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .inputContainer {
    flex: 1;
    flex-direction: column;
  }
  .mainButtonPadding {
    padding-left: 15px;
  }
  .bold {
    font-family: 'interSemiBold';
  }
  .buttonColumn {
    margin: 10px 10px 0 10px;
    padding: 10px;
    min-width: 100px;
    min-height: 20px;
    border-bottom-width: 1px;
    border-bottom-color: var(--elevation-dark-blue);
  }
  .screenH1 {
    font-family: 'SpaceGrotesk-SemiBold', inherit;
    font-size: 36px;
    font-weight: 800;
    font-style: normal;
    line-height: 34.7px;
    letter-spacing: -0.63px;
    text-align: left;
    color: var(--elevation-dark-blue);
    margin-bottom: 40px;
  }
  .bigHeading {
    font-family: 'SpaceGrotesk-Bold', inherit;
    font-size: 31.7px;
    font-weight: 600;
    font-style: normal;
    line-height: 34.7px;
    letter-spacing: -0.63px;
    text-align: left;
    color: var(--elevation-dark-blue);
    padding-bottom: 23px;
  }

  .listOfWifis {
    max-height: 200px;
    margin-top: 20px;
  }
  .wifiIndicator {
    width: 17.7px;
    height: 13px;
    margin-left: auto;
  }
  .wifiRowText {
    font-family: 'Inter', inherit;
    font-size: 16.7px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: -0.18px;
    color: var(--elevation-dark-blue);
    text-align: left;
  }
  .wifiRow {
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom-color: var(--elevation-light-blue);
    border-bottom-width: 1px;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }
  .paragraph {
    font-family: 'Inter', inherit;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 19.8px;
    letter-spacing: -0.33px;
    text-align: left;
    color: var(--elevation-dark-blue);
  }
  .diagnostics {
    color: var(--elevation-dark-blue);
    font-family: Inter, inherit;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 19.8px;
    letter-spacing: -0.33px;
    text-align: center;
  }
  .formContainer {
    flex: 2;
  }
  .addressLine {
    flex: 1;
  }
  .lightBlue {
    color: var(--elevation-light-blue);
  }
  .darkBlue {
    color: var(--elevation-dark-blue);
  }
  .input {
    background: var(--elevation-light-blue);
    color: var(--elevation-dark-blue);
    border-color: var(--elevation-dark-blue);
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom-width: 1.0px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 19.8px;
    letter-spacing: -0.33px;
    text-align: left;
  }
  .inputFont {
    font-family: Inter;
    font-size: 16.5px;
    font-weight: normal;
    font-style: normal;
    line-height: 19.8px;
    letter-spacing: -0.33px;
    text-align: left;
    color: var(--elevation-dark-blue);
    margin-bottom: -7px;
  }
  .inputError {
    border-bottom-color: var(--elevation-red);
  }
  .tipartiteLabel {
    font-family: 'SpaceGrotesk-Bold', inherit;
    padding-top: 10px;
    font-size: 13.5px;
    font-weight: 700;
    font-style: normal;
    line-height: 13.5px;
    margin-bottom: 5px;
    letter-spacing: 1.49px;
    height: 35px;
    color: var(--elevation-dark-grey)
  }
  .footerArrow {
    width: 12.3px;
    height: 10.7px;
    transform: rotate(180deg);
    opacity: 0.5px;
    position: relative;
    top: 9px;
  }
  .listArrow {
    width: 12.3px;
    height: 10.7px;
    transform: rotate(180deg);
    background-size: contain;
  }
  .highlightedTripartiteLabel {
    color: var(--elevation-dark-blue);
    padding-bottom: 11px;
    border-bottom-width: 1px;
    border-bottom-color: var(--elevation-light-blue);
  }
  .tripartiteBox {
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
  .icon {
    width: 28px;
    height: 28px;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 250px;
    z-index: 9;
  }
  .small {
    font-size: 14px;
  }
  .signup {
    font-family: 'Inter', inherit;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 26.5px;
    letter-spacing: -0.27px;
    text-align: left;
    color: var(--elevation-dark-blue);
  }
  .main-menu {
    width: 200px;
      float:right;
  }

  button {
    cursor: pointer;
  }
  select {
    padding: 4px;
    border: inherit;
    border-color: var(--elevation-dark-blue);
    border-radius: 4px;
  }