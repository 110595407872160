.container{
    flex-direction: 'row';
    justify-content: 'space-between';
    width: '100%';
    border-bottom-color:  #06285e;
    border-bottom-width: 1;
  }
  .buttonColumn{
    margin: 10 10 0 10;
    padding: 10;
    min-width: 100;
    min-height: 20;
    margin-left: 0;
    padding-left: 0;
    font-size: 21;
    padding-bottom: 0;
  }
  .listArrow{
    position: 'relative';
    top: 27;
    right: 10;
  }
  .small{
    font-size: 12;
  }